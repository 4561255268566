import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The creation of artificial threads is a relatively new school of `}<a parentName="p" {...{
        "href": "/Magic%20on%20Naos",
        "title": "Magic on Naos"
      }}>{`Magic on Naos`}</a>{` that allows `}<a parentName="p" {...{
        "href": "/Acronist",
        "title": "Acronist"
      }}>{`Acronists`}</a>{` to bind objects temporarily to `}<a parentName="p" {...{
        "href": "/The%20Luminary",
        "title": "The Luminary"
      }}>{`The Luminary`}</a>{` and bring it to "life".`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      